import {
  EmployeeStatusEnum,
  IcLookupEmployeeDataDto,
  IcLookupEmployeeItemDto,
} from '@data-access/bulk-operations-api';

import { createIcServiceMock } from './item-cache-service.base.mock';

const icEmployeesMock: IcLookupEmployeeItemDto[] = [
  IcLookupEmployeeItemDto.fromJS({
    id: 'id-employee-manager-1',
    data: {
      employee: {
        updatedAt: new Date('2024-01-01:03:33.330Z'),
        isDraft: false,
        id: 'id-employee-manager-1',
        hasUser: true,
        customId: 'cid-1-manager',
        firstName: 'Manager',
        lastName: 'Mock',
        gender: 'female',
        governmentId: '31033626080',
        defaultPayment: {
          type: 'external',
        },
      },
      position: {
        employeeId: 'id-employee-manager-1',
        positionId: 'id-employee-manager-1_position-1',
        from: new Date('2024-01-01'),
        taxUnitId: 'id-tax-unit-1',
        managerId: undefined,
        costUnits: [],
        tagIds: [],
        employmentType: 'ordinary',
        appointmentType: 'permanent',
        occupationCode: '1110123',
        workTimeAgreementId: 'id-wta-normal-40',
        ftePercentage: '100',
        actualHoursPerWeek: '40',
        salaryType: 'fixed',
        pay: {
          from: new Date('2014-01-01'),
          inputType: 'fullTimeYearly',
          hourlyWage: '59.19',
          fullTimeMonthlySalary: '10260.25',
          fullTimeYearlySalary: '123123',
        },
      },
    },
  }),
  IcLookupEmployeeItemDto.fromJS({
    id: 'id-employee-manager-2',
    data: {
      employee: {
        updatedAt: new Date('2025-01-13T13:03:59.973Z'),
        isDraft: false,
        id: 'id-employee-manager-2',
        hasUser: true,
        customId: 'cid-2-manager',
        firstName: 'Manager',
        lastName: 'Two',
        gender: 'female',
        governmentId: '29126900463',
        defaultPayment: {
          type: 'norwegianBank',
          accountNumber: '05402353696',
        },
        unionFrom: new Date('2017-01-01'),
      },
      position: {
        employeeId: 'id-employee-manager-2',
        positionId: 'id-employee-manager-2_position-1',
        from: new Date('2020-01-01'),
        taxUnitId: 'id-tax-unit-1',
        managerId: 'id-employee-manager-1',
        costUnits: [
          {
            typeCustomId: '1',
            valueCustomId: '1',
          },
        ],
        tagIds: [
          '91f3c1b1-3f13-4465-88bf-1768acef307b',
          '2bd34571-ad56-4733-b049-500927060ddd',
          '92d92e68-bd32-49c5-83e3-237f52fb091f',
        ],
        employmentType: 'freelancer',
        occupationCode: '2221142',
        workTimeAgreementId: 'id-wta-normal-40',
        ftePercentage: '90',
        actualHoursPerWeek: '36',
        salaryType: 'fixed',
        pay: {
          from: new Date('2020-01-01'),
          inputType: 'fullTimeMonthly',
          hourlyWage: '1184.06',
          fullTimeMonthlySalary: '230891',
          fullTimeYearlySalary: '2770692',
          rate4: '3',
        },
      },
    },
  }),
  IcLookupEmployeeItemDto.fromJS({
    id: 'id-employee-draft-3',
    data: {
      employee: {
        updatedAt: new Date('2025-01-13T13:03:59.973Z'),
        isDraft: true,
        id: 'id-employee-draft-3',
        hasUser: false,
        customId: 'cid-3-draft',
        firstName: 'Draft',
        lastName: 'Mock',
        governmentId: 'gov-id-draft-3',
      },
      position: {},
    },
  }),
  IcLookupEmployeeItemDto.fromJS({
    id: 'id-employee-inactive-4',
    data: {
      employee: {
        isDraft: false,
        id: 'id-employee-inactive-4',
        hasUser: false,
        customId: 'cid-4-inactive',
        firstName: 'Inactive',
        lastName: 'Mock',
        gender: 'female',
        governmentId: 'gov-id-inactive-4',
        defaultPayment: {
          type: 'norwegianBank',
          accountNumber: '05402353696',
        },
        unionFrom: new Date('2017-01-01'),
      },
      position: {
        employeeId: 'id-employee-inactive-4',
        positionId: 'id-employee-inactive-4_position-1',
        from: new Date('2020-01-01'),
        to: new Date('2020-12-31'),
        taxUnitId: 'id-tax-unit-1',
        managerId: 'id-employee-manager-1',
        costUnits: [
          {
            typeCustomId: '1',
            valueCustomId: '1',
          },
        ],
        tagIds: [],
        employmentType: 'freelancer',
        occupationCode: '2221142',
        workTimeAgreementId: 'id-wta-normal-37.5',
        ftePercentage: '100',
        actualHoursPerWeek: '37.5',
        salaryType: 'fixed',
        pay: {
          from: new Date('2020-01-01'),
          inputType: 'fullTimeMonthly',
          hourlyWage: '1184.06',
          fullTimeMonthlySalary: '230891',
          fullTimeYearlySalary: '2770692',
          rate4: '3',
        },
      },
    },
  }),
];

// Should be synced with mapper in the service
function mapToLookupModel(value: IcLookupEmployeeItemDto) {
  return {
    ...value.data.employee,
    position: { ...value.data.position, status: getStatus(value.data) },
    id: value.id,
    fullName: `${value.data.employee.firstName} ${value.data.employee.lastName}`,
  };
}

function getStatus(data: IcLookupEmployeeDataDto): EmployeeStatusEnum {
  const { from, to } = data.position;
  const now = new Date();

  if (data.employee.isDraft) {
    return EmployeeStatusEnum.Draft;
  } else if (from && from <= now && (!to || to > now)) {
    return EmployeeStatusEnum.Active;
  } else if (to && to <= now) {
    return EmployeeStatusEnum.Inactive;
  } else if (from && from > now) {
    return EmployeeStatusEnum.Future;
  } else {
    return EmployeeStatusEnum.Unknown;
  }
}

export function getIcEmployeesServiceMock() {
  return createIcServiceMock(icEmployeesMock.map(mapToLookupModel), icEmployeesMock);
}
