import { Injectable, computed } from '@angular/core';
import {
  IIcLookupTagDataDto,
  IcLookupDto,
  IcLookupTagItemDto,
  IcLookupTypeEnumDto,
} from '@data-access/bulk-operations-api';

import { IcServiceBase } from './ic-service.base';

@Injectable({ providedIn: 'root' })
export class IcTagsService extends IcServiceBase<IcLookupTagItemDto, IcTag> {
  protected override lookupType = IcLookupTypeEnumDto.Tag;

  public override readonly values = computed<IcTag[]>(() =>
    [...this.rawValues().values()]
      .map((item) => this.mapToLookupModel(item))
      .sort((a, b) => a.name.localeCompare(b.name)),
  );

  protected override getValuesFromLookupDto(lookup: IcLookupDto): IcLookupTagItemDto[] {
    return lookup.tags;
  }

  protected override mapToLookupModel(value: IcLookupTagItemDto): IcTag {
    return {
      ...value.data,
      id: value.id,
    };
  }
}

export type IcTag = IIcLookupTagDataDto & { id: string };
