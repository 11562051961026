import { IIcLookupTaxUnitItemDto, IcLookupTaxUnitItemDto } from '@data-access/bulk-operations-api';

import { IcTaxUnit } from '../services/ic-tax-units.service';
import { createIcServiceMock } from './item-cache-service.base.mock';

const icTaxUnits: IcLookupTaxUnitItemDto[] = [
  IcLookupTaxUnitItemDto.fromJS({
    data: {
      name: 'Tax unit #1',
      organizationNumber: '843456782',
    },
    version: 1,
    id: 'id-tax-unit-1',
  } as IIcLookupTaxUnitItemDto),
];

// Should be synced with mapper in the service
function mapToLookupModel(value: IcLookupTaxUnitItemDto): IcTaxUnit {
  return {
    ...value.data,
    id: value.id,
  };
}

export function getIcTaxUnitsServiceMock() {
  return createIcServiceMock(icTaxUnits.map(mapToLookupModel), icTaxUnits);
}
