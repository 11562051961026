import { Injectable } from '@angular/core';
import {
  IIcLookupPaycodeForExtraPaymentDataDto,
  IcLookupDto,
  IcLookupPaycodeForExtraPaymentItemDto,
  IcLookupTypeEnumDto,
} from '@data-access/bulk-operations-api';
import { getTranslationFromLocalizedString } from '@em-helpers';

import { IcServiceBase } from './ic-service.base';

@Injectable({ providedIn: 'root' })
export class IcPaycodesForExtraPaymentService extends IcServiceBase<
  IcLookupPaycodeForExtraPaymentItemDto,
  IcPaycodeForExtraPayment
> {
  protected override lookupType = IcLookupTypeEnumDto.PaycodeForEp;

  protected override mapToLookupModel(
    value: IcLookupPaycodeForExtraPaymentItemDto,
  ): IcPaycodeForExtraPayment {
    return {
      ...value.data,
      name: getTranslationFromLocalizedString(value.data.name),
      description: getTranslationFromLocalizedString(value.data.description),
      displayName: `${value.data.customId} - ${getTranslationFromLocalizedString(value.data.name)}`,
      id: value.id,
    };
  }

  protected override getValuesFromLookupDto(
    lookup: IcLookupDto,
  ): IcLookupPaycodeForExtraPaymentItemDto[] {
    return lookup.paycodesForExtraPayment;
  }
}

export type IcPaycodeForExtraPayment = Omit<
  IIcLookupPaycodeForExtraPaymentDataDto,
  'name' | 'description'
> & {
  id: string;
  name: string;
  displayName: string;
  description: string;
};
