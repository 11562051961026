import { Injectable } from '@angular/core';
import {
  IIcLookupCostUnitTypeDataDto,
  IcLookupCostUnitTypeItemDto,
  IcLookupDto,
  IcLookupTypeEnumDto,
} from '@data-access/bulk-operations-api';

import { IcServiceBase } from './ic-service.base';

@Injectable({ providedIn: 'root' })
export class IcCostUnitTypesService extends IcServiceBase<
  IcLookupCostUnitTypeItemDto,
  IcCostUnitType
> {
  protected override lookupType = IcLookupTypeEnumDto.CostUnitType;

  protected override mapToLookupModel(value: IcLookupCostUnitTypeItemDto): IcCostUnitType {
    return {
      ...value.data,
      id: value.id,
    };
  }

  protected override getValuesFromLookupDto(lookup: IcLookupDto): IcLookupCostUnitTypeItemDto[] {
    return lookup.costUnitTypes;
  }
}

export type IcCostUnitType = IIcLookupCostUnitTypeDataDto & { id: string };
