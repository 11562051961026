import { Injectable } from '@angular/core';
import {
  IIcLookupCarDataDto,
  IcLookupCarItemDto,
  IcLookupDto,
  IcLookupTypeEnumDto,
} from '@data-access/bulk-operations-api';

import { IcServiceBase } from './ic-service.base';

@Injectable({ providedIn: 'root' })
export class IcCarsService extends IcServiceBase<IcLookupCarItemDto, IcCar> {
  protected override lookupType = IcLookupTypeEnumDto.Car;

  protected override mapToLookupModel(value: IcLookupCarItemDto): IcCar {
    return {
      ...value.data,
      id: value.id,
    };
  }

  protected override getValuesFromLookupDto(lookup: IcLookupDto): IcLookupCarItemDto[] {
    return lookup.cars;
  }
}

export type IcCar = IIcLookupCarDataDto & { id: string };
