import { Injectable } from '@angular/core';
import {
  IIcLookupPensionCompanyDataDto,
  IcLookupDto,
  IcLookupPensionCompanyItemDto,
  IcLookupTypeEnumDto,
} from '@data-access/bulk-operations-api';

import { IcServiceBase } from './ic-service.base';

@Injectable({ providedIn: 'root' })
export class IcPensionCompaniesService extends IcServiceBase<
  IcLookupPensionCompanyItemDto,
  IcPensionCompany
> {
  protected override lookupType = IcLookupTypeEnumDto.PensionCompany;

  protected override mapToLookupModel(value: IcLookupPensionCompanyItemDto): IcPensionCompany {
    return {
      ...value.data,
      id: value.id,
    };
  }

  protected override getValuesFromLookupDto(lookup: IcLookupDto): IcLookupPensionCompanyItemDto[] {
    return lookup.pensionCompanies;
  }
}

export type IcPensionCompany = IIcLookupPensionCompanyDataDto & { id: string };
