import { Injectable } from '@angular/core';
import {
  IIcLookupEventCodeForAbsenceRegistrationDataDto,
  IcLookupDto,
  IcLookupEventCodeForAbsenceRegistrationItemDto,
  IcLookupTypeEnumDto,
} from '@data-access/bulk-operations-api';
import { getTranslationFromLocalizedString } from '@em-helpers';

import { IcServiceBase } from './ic-service.base';

@Injectable({ providedIn: 'root' })
export class IcEventCodesForAbsenceRegistrationService extends IcServiceBase<
  IcLookupEventCodeForAbsenceRegistrationItemDto,
  IcEventCodeForAbsenceRegistration
> {
  protected override lookupType = IcLookupTypeEnumDto.EventCodeForAr;

  protected override mapToLookupModel(
    value: IcLookupEventCodeForAbsenceRegistrationItemDto,
  ): IcEventCodeForAbsenceRegistration {
    return {
      ...value.data,
      name: getTranslationFromLocalizedString(value.data.name),
      id: value.id,
    };
  }

  protected override getValuesFromLookupDto(
    lookup: IcLookupDto,
  ): IcLookupEventCodeForAbsenceRegistrationItemDto[] {
    return lookup.eventCodesForAbsenceRegistration;
  }
}

export type IcEventCodeForAbsenceRegistration = Omit<
  IIcLookupEventCodeForAbsenceRegistrationDataDto,
  'name'
> & { id: string; name: string };
