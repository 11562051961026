import { Injectable } from '@angular/core';
import {
  IIcLookupWtaDataDto,
  IcLookupDto,
  IcLookupTypeEnumDto,
  IcLookupWtaItemDto,
} from '@data-access/bulk-operations-api';

import { IcServiceBase } from './ic-service.base';

@Injectable({ providedIn: 'root' })
export class IcWorkTimeAgreementsService extends IcServiceBase<IcLookupWtaItemDto, IcWta> {
  protected override lookupType = IcLookupTypeEnumDto.Wta;

  protected override mapToLookupModel(value: IcLookupWtaItemDto): IcWta {
    return {
      ...value.data,
      id: value.id,
    };
  }

  protected override getValuesFromLookupDto(lookup: IcLookupDto): IcLookupWtaItemDto[] {
    return lookup.wtas;
  }
}

export type IcWta = IIcLookupWtaDataDto & { id: string };
