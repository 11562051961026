import { IcLookupTagItemDto } from '@data-access/bulk-operations-api';

import { IcTag } from '../services/ic-tags.service';
import { createIcServiceMock } from './item-cache-service.base.mock';

const icTags: IcLookupTagItemDto[] = [
  IcLookupTagItemDto.fromJS({
    data: {
      name: 'Tag red',
      description: 'Tag red description',
      color: '#FF0000',
    },
    id: 'id-tag-red',
  }),
  IcLookupTagItemDto.fromJS({
    data: {
      name: 'Tag blue',
      description: 'Tag blue description',
      color: '#0000FF',
    },
    id: 'id-tag-blue',
  }),
];

// Should be synced with mapper in the service
function mapToLookupModel(value: IcLookupTagItemDto): IcTag {
  return {
    ...value.data,
    id: value.id,
  };
}

export function getIcTagsServiceMock() {
  return createIcServiceMock(icTags.map(mapToLookupModel), icTags);
}
