import { Injectable } from '@angular/core';
import {
  IIcLookupTaxUnitDataDto,
  IcLookupDto,
  IcLookupTaxUnitItemDto,
  IcLookupTypeEnumDto,
} from '@data-access/bulk-operations-api';

import { IcServiceBase } from './ic-service.base';

@Injectable({ providedIn: 'root' })
export class IcTaxUnitsService extends IcServiceBase<IcLookupTaxUnitItemDto, IcTaxUnit> {
  protected override lookupType = IcLookupTypeEnumDto.TaxUnit;

  protected override mapToLookupModel(value: IcLookupTaxUnitItemDto): IcTaxUnit {
    return {
      ...value.data,
      id: value.id,
    };
  }

  protected override getValuesFromLookupDto(lookup: IcLookupDto): IcLookupTaxUnitItemDto[] {
    return lookup.taxUnits;
  }
}

export type IcTaxUnit = IIcLookupTaxUnitDataDto & { id: string };
