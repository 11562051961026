import { IcLookupDto, IcLookupInitialResponseDto } from '@data-access/bulk-operations-api';

export function lookupReducer(
  acc: IcLookupInitialResponseDto | undefined,
  response: IcLookupInitialResponseDto,
) {
  if (!acc) return response;

  acc.lookup = new IcLookupDto({
    cars: [...acc.lookup.cars, ...response.lookup.cars],
    costUnitTypes: [...acc.lookup.costUnitTypes, ...response.lookup.costUnitTypes],
    costUnitValues: [...acc.lookup.costUnitValues, ...response.lookup.costUnitValues],
    creditors: [...acc.lookup.creditors, ...response.lookup.creditors],
    employees: [...acc.lookup.employees, ...response.lookup.employees],
    eventCodesForAbsenceRegistration: [
      ...acc.lookup.eventCodesForAbsenceRegistration,
      ...response.lookup.eventCodesForAbsenceRegistration,
    ],
    paycodesForExtraPayment: [
      ...acc.lookup.paycodesForExtraPayment,
      ...response.lookup.paycodesForExtraPayment,
    ],
    paycodesForFixedTransaction: [
      ...acc.lookup.paycodesForFixedTransaction,
      ...response.lookup.paycodesForFixedTransaction,
    ],
    pensionCompanies: [...acc.lookup.pensionCompanies, ...response.lookup.pensionCompanies],
    settings: acc.lookup.settings,
    tags: [...acc.lookup.tags, ...response.lookup.tags],
    taxUnits: [...acc.lookup.taxUnits, ...response.lookup.taxUnits],
    unions: [...acc.lookup.unions, ...response.lookup.unions],
    wtas: [...acc.lookup.wtas, ...response.lookup.wtas],
  });

  return acc;
}
