import { Injectable } from '@angular/core';
import {
  IIcLookupPaycodeForFixedTransactionDataDto,
  IcLookupDto,
  IcLookupPaycodeForFixedTransactionItemDto,
  IcLookupTypeEnumDto,
} from '@data-access/bulk-operations-api';
import { getTranslationFromLocalizedString } from '@em-helpers';

import { IcServiceBase } from './ic-service.base';

@Injectable({ providedIn: 'root' })
export class IcPaycodesForFixedTransactionService extends IcServiceBase<
  IcLookupPaycodeForFixedTransactionItemDto,
  IcPaycodeForFixedTransaction
> {
  protected override lookupType = IcLookupTypeEnumDto.PaycodeForFt;

  protected override mapToLookupModel(
    value: IcLookupPaycodeForFixedTransactionItemDto,
  ): IcPaycodeForFixedTransaction {
    return {
      ...value.data,
      name: getTranslationFromLocalizedString(value.data.name),
      description: getTranslationFromLocalizedString(value.data.description),
      displayName: `${value.data.customId} - ${getTranslationFromLocalizedString(value.data.name)}`,
      id: value.id,
    };
  }

  protected override getValuesFromLookupDto(
    lookup: IcLookupDto,
  ): IcLookupPaycodeForFixedTransactionItemDto[] {
    return lookup.paycodesForFixedTransaction;
  }
}

export type IcPaycodeForFixedTransaction = Omit<
  IIcLookupPaycodeForFixedTransactionDataDto,
  'name' | 'description'
> & {
  id: string;
  name: string;
  displayName: string;
  description: string;
};
