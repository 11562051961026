import { Injectable, computed, inject } from '@angular/core';
import {
  IcLookupInitialResponseDto,
  IcLookupTypeEnumDto,
  ItemCacheClient,
} from '@data-access/bulk-operations-api';
import { EMPTY, expand, reduce, take } from 'rxjs';

import { lookupReducer } from '../helpers/lookup-reducer';
import { IcCarsService } from './ic-cars.service';
import { IcCostUnitTypesService } from './ic-cost-unit-types.service';
import { IcCostUnitValuesService } from './ic-cost-unit-values.service';
import { IcCreditorsService } from './ic-creditors.service';
import { IcEmployeesService } from './ic-employees.service';
import { IcEventCodesForAbsenceRegistrationService } from './ic-event-codes-for-absence.service';
import { IcPaycodesForExtraPaymentService } from './ic-paycodes-for-extra-payment.service';
import { IcPaycodesForFixedTransactionService } from './ic-paycodes-for-fixed-transaction.service';
import { IcPensionCompaniesService } from './ic-pension-companies.service';
import { IcSettingsService } from './ic-settings.service';
import { IcTagsService } from './ic-tags.service';
import { IcTaxUnitsService } from './ic-tax-units.service';
import { IcUnionsService } from './ic-unions.service';
import { IcWorkTimeAgreementsService } from './ic-work-time-agreements.service';

@Injectable({ providedIn: 'root' })
export class ItemCacheService {
  private readonly client = inject(ItemCacheClient);

  private readonly icSettingsService = inject(IcSettingsService);
  private readonly icEmployeesService = inject(IcEmployeesService);
  private readonly icCostUnitTypesService = inject(IcCostUnitTypesService);
  private readonly icCarsService = inject(IcCarsService);
  private readonly icCostUnitValuesService = inject(IcCostUnitValuesService);
  private readonly icCreditorsService = inject(IcCreditorsService);
  private readonly icEventCodesForAbsenceRegistrationService = inject(
    IcEventCodesForAbsenceRegistrationService,
  );
  private readonly icPaycodesForExtraPaymentService = inject(IcPaycodesForExtraPaymentService);
  private readonly icPaycodesForFixedTransactionService = inject(
    IcPaycodesForFixedTransactionService,
  );
  private readonly icPensionCompaniesService = inject(IcPensionCompaniesService);
  private readonly icTagsService = inject(IcTagsService);
  private readonly icTaxUnitsService = inject(IcTaxUnitsService);
  private readonly icUnionsService = inject(IcUnionsService);
  private readonly icWorkTimeAgreementsService = inject(IcWorkTimeAgreementsService);

  public readonly isInitialized = computed(() => {
    const services = [
      this.icSettingsService,
      this.icEmployeesService,
      this.icCostUnitTypesService,
      this.icCarsService,
      this.icCostUnitValuesService,
      this.icCreditorsService,
      this.icEventCodesForAbsenceRegistrationService,
      this.icPaycodesForExtraPaymentService,
      this.icPaycodesForFixedTransactionService,
      this.icPensionCompaniesService,
      this.icTagsService,
      this.icTaxUnitsService,
      this.icUnionsService,
      this.icWorkTimeAgreementsService,
    ];

    return services.every((service) => service.isInitialized());
  });

  constructor() {
    this.fetch();
  }

  public clearCache() {
    this.client.getIcStartClear().pipe(take(1)).subscribe();
  }

  private fetch() {
    this.client
      .getIcLookup()
      .pipe(
        take(1),
        expand((response) =>
          response.lookup.nextToken
            ? this.client.getIcLookup(undefined, response.lookup.nextToken)
            : EMPTY,
        ),
        reduce(lookupReducer),
      )
      .subscribe((data) => {
        this.icSettingsService.needsOnboarding.set(data.needsOnboarding);
        this.icSettingsService.segmentError.set(data.segmentError);

        this.setCaches(data);
      });
  }

  private setCaches(data: IcLookupInitialResponseDto) {
    this.icSettingsService.setCache(
      data.lookup.settings,
      data.initializing.includes(IcLookupTypeEnumDto.Settings),
    );
    this.icEmployeesService.setCache(
      data.lookup.employees,
      data.initializing.includes(IcLookupTypeEnumDto.Employee),
    );
    this.icCostUnitTypesService.setCache(
      data.lookup.costUnitTypes,
      data.initializing.includes(IcLookupTypeEnumDto.CostUnitType),
    );
    this.icCarsService.setCache(
      data.lookup.cars,
      data.initializing.includes(IcLookupTypeEnumDto.Car),
    );
    this.icCostUnitValuesService.setCache(
      data.lookup.costUnitValues,
      data.initializing.includes(IcLookupTypeEnumDto.Cuv),
    );
    this.icCreditorsService.setCache(
      data.lookup.creditors,
      data.initializing.includes(IcLookupTypeEnumDto.Creditor),
    );
    this.icEventCodesForAbsenceRegistrationService.setCache(
      data.lookup.eventCodesForAbsenceRegistration,
      data.initializing.includes(IcLookupTypeEnumDto.EventCodeForAr),
    );
    this.icPaycodesForExtraPaymentService.setCache(
      data.lookup.paycodesForExtraPayment,
      data.initializing.includes(IcLookupTypeEnumDto.PaycodeForEp),
    );
    this.icPaycodesForFixedTransactionService.setCache(
      data.lookup.paycodesForFixedTransaction,
      data.initializing.includes(IcLookupTypeEnumDto.PaycodeForFt),
    );
    this.icPensionCompaniesService.setCache(
      data.lookup.pensionCompanies,
      data.initializing.includes(IcLookupTypeEnumDto.PensionCompany),
    );
    this.icTagsService.setCache(
      data.lookup.tags,
      data.initializing.includes(IcLookupTypeEnumDto.Tag),
    );
    this.icTaxUnitsService.setCache(
      data.lookup.taxUnits,
      data.initializing.includes(IcLookupTypeEnumDto.TaxUnit),
    );
    this.icUnionsService.setCache(
      data.lookup.unions,
      data.initializing.includes(IcLookupTypeEnumDto.Union),
    );
    this.icWorkTimeAgreementsService.setCache(
      data.lookup.wtas,
      data.initializing.includes(IcLookupTypeEnumDto.Wta),
    );
  }
}
