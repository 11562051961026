import { Injectable, effect, inject, signal, untracked } from '@angular/core';
import {
  EmployeeStatusEnum,
  IIcLookupEmployeeDefaultPaymentDto,
  IIcLookupEmployeeDto,
  IIcLookupPositionCostUnitDto,
  IIcLookupPositionDto,
  IIcLookupPositionPayDto,
  IcLookupDto,
  IcLookupEmployeeDataDto,
  IcLookupEmployeeItemDto,
  IcLookupPositionPayDto,
  IcLookupTypeEnumDto,
} from '@data-access/bulk-operations-api';
import {
  WsIcUpsertedEmployeeItem,
  WsIcUpsertedPositionItem,
  WsServerPayloadEnum,
} from '@data-access/bulk-operations-ws';
import { calculateSalariesUsingIcLookupPositionPayDto, getTimelineItemAtDate } from '@em-helpers';
import { delayWhen, filter, iif, of, take } from 'rxjs';

import { IcServiceBase } from './ic-service.base';
import { IcWorkTimeAgreementsService, IcWta } from './ic-work-time-agreements.service';

@Injectable({ providedIn: 'root' })
export class IcEmployeesService extends IcServiceBase<IcLookupEmployeeItemDto, IcEmployee> {
  protected override lookupType = IcLookupTypeEnumDto.Employee;

  private readonly icWorkTimeAgreements = inject(IcWorkTimeAgreementsService).valuesMap;

  private readonly lastUpdatedAtInternal = signal<Date | null>(null);
  public readonly lastUpdatedAt = this.lastUpdatedAtInternal.asReadonly();

  protected constructor() {
    super();
    effect(() => {
      // Need to call wta signal here to trigger effect
      const wtas = this.icWorkTimeAgreements();
      untracked(() => {
        this.rawValues.update((values) => {
          values.forEach((item) => {
            this.updateSalaries(item, wtas);
          });

          return new Map(values);
        });
      });
    });
  }

  protected override mapToLookupModel(value: IcLookupEmployeeItemDto): IcEmployee {
    return {
      ...value.data.employee,
      position: { ...value.data.position, status: this.getStatus(value.data) },
      id: value.id,
      fullName: `${value.data.employee.firstName} ${value.data.employee.lastName}`,
    };
  }

  public override setCache(
    lookupValues: IcLookupEmployeeItemDto[],
    isInitialization = false,
  ): void {
    this.rawValues.set(
      new Map(
        lookupValues.map((item) => {
          this.updateSalaries(item, this.icWorkTimeAgreements());
          this.updateLastUpdatedAt(item);

          return [item.id, item];
        }),
      ),
    );
    if (!isInitialization) {
      this.isInitialized.set(true);
    }
  }

  public override startSync(employeeId?: string) {
    this.client.getIcStartSync(this.lookupType, employeeId).pipe(take(1)).subscribe();
  }

  public override upsertCacheItem(newValue: IcLookupEmployeeItemDto) {
    this.upsertCacheItems([newValue]);
  }

  public override upsertCacheItems(newValues: IcLookupEmployeeItemDto[]) {
    this.rawValues.update((values) => {
      newValues.forEach((newValue) => {
        const currentValue = values.get(newValue.id);

        // We will remove version if there are no errors
        if (currentValue && currentValue.version > newValue.version) {
          console.error(
            `ItemCache: [${this.lookupType}] received WS message with out-of-date version. ID: ${newValue.id}, v: ${newValue.version} (v: ${currentValue.version})`,
          );
        }

        this.updateSalaries(newValue, this.icWorkTimeAgreements());
        this.updateLastUpdatedAt(newValue);

        values.set(newValue.id, newValue);
      });

      return new Map(values);
    });
  }

  protected override getValuesFromLookupDto(lookup: IcLookupDto): IcLookupEmployeeItemDto[] {
    return lookup.employees;
  }

  protected override subscribeToWsUpsert() {
    this.wsUpsertSubscription?.unsubscribe();

    this.wsUpsertSubscription = this.wsClient
      .getMessagesByType(WsServerPayloadEnum.IcUpserted)
      .pipe(
        filter((msg) => msg.type === IcLookupTypeEnumDto.Employee),
        // Hold processing to avoid overwrites
        delayWhen(() =>
          iif(
            () => this.isInitialized(),
            of(true),
            this.isInitialized$.pipe(filter((isInitialized) => isInitialized)),
          ),
        ),
      )
      .subscribe((msg) => {
        // TODO: optimize if we will start getting batched messages
        msg.employeeItems?.forEach((item) => this.handleEmployeeUpsert(item));
        msg.positionItems?.forEach((item) => this.handlePositionUpsert(item));
      });
  }

  private handleEmployeeUpsert(msg: WsIcUpsertedEmployeeItem) {
    const currentValue = this.rawValues().get(msg.id);

    if (!currentValue) {
      const newValue = {
        data: {
          employee: msg.lookup,
          position: {},
        },
        id: msg.id,
        version: msg.version,
      };

      this.upsertCacheItem(IcLookupEmployeeItemDto.fromJS(newValue));
      // Return needed to avoid further processing
      return;
    }

    if (msg.lookup) {
      const newValue = {
        data: {
          ...currentValue?.data,
          employee: msg.lookup,
        },
        id: msg.id,
        version: msg.version,
      };

      this.upsertCacheItem(IcLookupEmployeeItemDto.fromJS(newValue));
    }
  }

  private handlePositionUpsert(msg: WsIcUpsertedPositionItem) {
    const currentValue = this.rawValues().get(msg.employeeId);

    if (!currentValue) {
      const newValue = {
        data: {
          employee: {},
          position: msg.lookup,
        },
        id: msg.employeeId,
        version: msg.version,
      };

      this.upsertCacheItem(IcLookupEmployeeItemDto.fromJS(newValue));
      return;
    }

    // Position uses different version from employees - do not check it
    if (msg.lookup) {
      const newValue = {
        data: {
          ...currentValue?.data,
          position: msg.lookup,
        },
        id: msg.employeeId,
        version: currentValue.version,
      };

      this.upsertCacheItem(IcLookupEmployeeItemDto.fromJS(newValue));
    }
  }

  private updateLastUpdatedAt(lookupDto: IcLookupEmployeeItemDto) {
    const lastUpdatedAt = this.lastUpdatedAt();
    const newUpdatedAt = lookupDto.data.employee.updatedAt;

    if (!lastUpdatedAt || lastUpdatedAt < newUpdatedAt) {
      this.lastUpdatedAtInternal.set(newUpdatedAt);
    }
  }

  private updateSalaries(
    item: IcLookupEmployeeItemDto,
    workTimeAgreements: ReadonlyMap<string, IcWta>,
  ) {
    if (item.data.position.workTimeAgreementId) {
      const wta = workTimeAgreements.get(item.data.position.workTimeAgreementId);
      if (wta) {
        const calculatedSalaries = calculateSalariesUsingIcLookupPositionPayDto(
          item.data.position.pay,
          getTimelineItemAtDate(wta.normalWeeklyHoursTimeline, item.data.position.pay.from)?.value
            .hourlyWage,
        );
        item.data.position.pay = new IcLookupPositionPayDto({
          ...item.data.position.pay,
          ...calculatedSalaries,
        });
      }
    }
  }

  private getStatus(data: IcLookupEmployeeDataDto): EmployeeStatusEnum {
    const { from, to } = data.position;
    const now = new Date();

    if (data.employee.isDraft) {
      return EmployeeStatusEnum.Draft;
    } else if (from && from <= now && (!to || to > now)) {
      return EmployeeStatusEnum.Active;
    } else if (to && to <= now) {
      return EmployeeStatusEnum.Inactive;
    } else if (from && from > now) {
      return EmployeeStatusEnum.Future;
    } else {
      return EmployeeStatusEnum.Unknown;
    }
  }
}

export type IcEmployee = Omit<IIcLookupEmployeeDto, 'defaultPayment'> & {
  defaultPayment?: IIcLookupEmployeeDefaultPaymentDto;
  position: IcPosition;
  fullName: string;
};
export type IcPosition = Omit<IIcLookupPositionDto, 'pay' | 'costUnits'> & {
  pay: IIcLookupPositionPayDto;
  costUnits: IIcLookupPositionCostUnitDto[];
  status: EmployeeStatusEnum;
};
