import { Injectable } from '@angular/core';
import {
  IIcLookupCreditorDataDto,
  IcLookupCreditorItemDto,
  IcLookupDto,
  IcLookupTypeEnumDto,
} from '@data-access/bulk-operations-api';

import { IcServiceBase } from './ic-service.base';

@Injectable({ providedIn: 'root' })
export class IcCreditorsService extends IcServiceBase<IcLookupCreditorItemDto, IcCreditor> {
  protected override lookupType = IcLookupTypeEnumDto.Creditor;

  protected override mapToLookupModel(value: IcLookupCreditorItemDto): IcCreditor {
    return {
      ...value.data,
      id: value.id,
    };
  }

  protected override getValuesFromLookupDto(lookup: IcLookupDto): IcLookupCreditorItemDto[] {
    return lookup.creditors;
  }
}

export type IcCreditor = IIcLookupCreditorDataDto & { id: string };
